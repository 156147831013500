import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from "react-i18next";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import axios from "axios";
import Skeleton from "@material-ui/lab/Skeleton";
import MaterialTable, { MTableToolbar } from "material-table";
import tableLocalizations from '../common/tableLocalizations.json';
//import LinkOffIcon from '@material-ui/icons/LinkOff';
//import LinkIcon from '@material-ui/icons/Link';
//import Button from "@material-ui/core/Button";
//import {isAuthenticated} from "../../plugins/AuthService";
import MachineDataDialog from "../common/MachineDataDialog";
import { NotificationManager } from "react-notifications";

const useStyles = makeStyles((theme) => ({
    depositContext: {
        flex: 1,
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        textAlign: 'center'
    },
    fixedHeight: {
        height: 180,
    },
    fixedHeightSkeleton: {
        height: 180,
        justifyContent: 'center',
        alignItems: 'center'
    },
}));

export default function MachinesList(props) {
    const { t, i18n } = useTranslation()
    const classes = useStyles();
    const fixedHeightSkeleton = clsx(classes.paper, classes.fixedHeightSkeleton);
    const [MachinesData, setMachinesData] = useState({});
    const [selectedMachinesData, setSelectedMachinesData] = useState(null);

    const [openMachineDataDialog, setOpenMachineDataDialog] = React.useState(false);

    const handleCloseMachineDataDialog = () => {
        setOpenMachineDataDialog(false);
        setSelectedMachinesData(null);
        fetchMachinesData();
    };
    const fetchMachinesData = () => {

        return new Promise((resolve, reject) => {
            axios.get('/api/v1/machines')
                .then(response => {
                    return response.data.machines;
                })
                .then(result => {
                    setMachinesData(result)
                })
                .catch(error => {
                    console.log(error.response)
                    NotificationManager.error((error && error.response) ? error.response.data.error || error.response.data : '', 'Oops!', 3000);
                })
        })

    };

    useEffect(() => {
        if (MachinesData.machines) {
        } else {
            fetchMachinesData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <React.Fragment>
            <Grid container spacing={0}>

                <Grid item xs={12} md={12} lg={12}>
                    {!MachinesData.length
                        ?
                        <Paper className={fixedHeightSkeleton}>
                            <Skeleton animation="wave" width="100%" />
                            <Skeleton variant="circle" width={40} height={40} />
                            <Skeleton animation="wave" width="100%" />
                        </Paper>
                        :
                        <MaterialTable
                            style={{ boxShadow: "none" }}
                            columns={[
                                { title: t('name'), field: "machine.name", cellStyle: { textAlign: 'center' } },
                                { title: t('model'), field: "machine.model", cellStyle: { textAlign: 'center' } },
                                { title: t('serial'), field: "machine.serial", cellStyle: { textAlign: 'center' } },


                            ]}
                            localization={tableLocalizations[i18n.language]}
                            data={MachinesData}
                            title={t('machine_list')}
                            options={{
                                // actionsColumnIndex: -1,
                                actionsCellStyle: { color: 'red' },
                                header: true,
                                search: false,
                                showTitle: true,
                                pageSize: 5,
                                headerStyle: { textAlign: 'center' },
                                rowStyle: { textAlign: 'center' },
                                paginationType: 'stepped'
                            }}

                            components={{
                                Toolbar: props => (
                                    <div>

                                        <MTableToolbar {...props} />
                                    </div>
                                ),
                            }}
                            actions={[
                                {
                                    icon: 'edit',
                                    tooltip: 'Edit Room',

                                    onClick: (event, rowData) => {

                                        setOpenMachineDataDialog(true);
                                        setSelectedMachinesData(rowData)
                                    }
                                }
                            ]}
                        />
                    }
                </Grid>
                {
                    selectedMachinesData
                        ? <MachineDataDialog
                            isOpen={openMachineDataDialog}
                            closeDialog={handleCloseMachineDataDialog}
                            machinesData={selectedMachinesData} />


                        : <span />
                }
            </Grid>
        </React.Fragment>
    );
}
